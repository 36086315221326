
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppExcerptIndex} from "@/models/app/excerpt";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElRow,
  ElCol,
  ElPagination,
} from "element-plus";
import {Right} from "@element-plus/icons-vue";
import AppAdministratorInvoicesExcerptsRootListFormFilterIndexVue from "@/views/app/administrator/invoices/excerpts/root/list/form/filter.vue";
import AppAdministratorInvoicesExcerptsListFormCreateIndexVue from "@/views/app/administrator/invoices/excerpts/root/list/form/create.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getDateFormat, getDateYearFormat, getYearFirstDay, getYearLastDay} from "@/services/app/date";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElRow,
    ElCol,
    ElPagination,
    Right,
    AppAdministratorInvoicesExcerptsRootListFormFilterIndexVue,
    AppAdministratorInvoicesExcerptsListFormCreateIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorInvoicesExcerptsRootListIndexVue extends Vue {
  isLoading = false;

  searchQuery = "*";
  period = [
    getYearFirstDay(),
    getYearLastDay(),
  ];
  year = new Date().getFullYear();
  pagination: PaginationIndex = new PaginationIndex();

  excerpts: Array<AppExcerptIndex> = [];

  get isDataLoaded(): boolean {
    return this.excerpts.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.excerpts.length === 0 && this.isLoading === false;
  }

  get isPaginationVisible(): boolean {
    return this.pagination.last_page > 1 && this.isLoading === false;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getDateYearFormat(data: string): string {
    return getDateYearFormat(data);
  }

  get translation(): any {
    return getTranslation([
      "excerpt",
      "excerpts",
      "noData",
    ]);
  }

  async getExcerpts(searchQuery = "*", period = [getYearFirstDay(), getYearLastDay()], year = new Date().getFullYear(), page = 1): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/excerpts`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        searchQuery: this.searchQuery = searchQuery,
        period: this.period = period,
        year: this.year = year,
        page: this.pagination.current_page = page,
        pagination: true,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.excerpts = r.data.data;
        this.pagination = {
          current_page: r.data.current_page,
          first_page_url: r.data.first_page_url,
          from: r.data.from,
          last_page: r.data.last_page,
          last_page_url: r.data.last_page_url,
          next_page_url: r.data.next_page_url,
          path: r.data.path,
          per_page: r.data.per_page,
          prev_page_url: r.data.prev_page_url,
          to: r.data.to,
          total: r.data.total,
        };
      }
    });
    this.isLoading = false;
  }

  setPagination(page): void {
    this.getExcerpts(this.searchQuery, this.period, this.year, page);
  }

  async created(): Promise<void> {
    await this.getExcerpts();
  }
}
