
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElDatePicker,
  ElSelect,
  ElOption,
} from "element-plus";

// Services
import {getDateEndTime, getDateStartTime, getYearFirstDay, getYearLastDay} from "@/services/app/date";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElDatePicker,
    ElSelect,
    ElOption,
  },
})
export default class AppAdministratorInvoicesExcerptsRootListFormFilterIndexVue extends Vue {
  isLoading = false;

  formData = {
    searchQuery: "",
    period: [
      getYearFirstDay(),
      getYearLastDay(),
    ],
    year: new Date().getFullYear(),
  };

  years: Array<number> = this.getYears();

  get translation(): any {
    return getTranslation([
      "choose",
      "filter",
      "search",
      "year",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  async submitForm(): Promise<void> {
    this.$emit('getExcerpts', this.formData.searchQuery.length ? this.formData.searchQuery : "*", this.formData.period, this.formData.year);
  }

  getYears(): Array<number> {
    const years = [];

    for (let i = new Date().getFullYear(); i >= 2006; i--) {
      years.push(i);
    }

    return years;
  }

  async setSearch(): Promise<void> {
    if (this.formData.searchQuery.length > 0 || this.formData.searchQuery.length === 0) {
      await this.submitForm();
    }
  }

  setPeriod(period: any): void {
    this.formData.period = [
      getDateStartTime(period[0]),
      getDateEndTime(period[1]),
    ];
    this.submitForm();
  }
}
