// Store
import store from "@/store";

export interface AppExcerptCreateIndex {
  organization_id: string | string[];
  file: any;
}

export class AppExcerptCreateIndex implements AppExcerptCreateIndex {
  organization_id: string | string[];
  file: any;

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.file = null;
  }
}