import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9b3278a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-invoices-excerpts-list-form-create" }
const _hoisted_2 = { class: "app-administrator-invoices-excerpts-list-form-create__action" }
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
      }, [
        _createVNode(_component_Plus)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.add,
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          class: "el-form--default",
          model: _ctx.formData,
          rules: _ctx.formDataRules,
          ref: "formComponent",
          onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "drag-and-drop",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickChoose && _ctx.onClickChoose(...args))),
                  onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translation.dragAndDropXMLFile), 1),
                  _createElementVNode("input", {
                    type: "file",
                    ref: "formFileInput",
                    accept: ".xml",
                    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChoose && _ctx.onChoose(...args)))
                  }, null, 544)
                ], 32)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}