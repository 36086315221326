
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorNavigationInvoicesVue from "@/components/administrator/navigation-invoices.vue";
import AppAdministratorInvoicesExcerptsRootListIndexVue from "@/views/app/administrator/invoices/excerpts/root/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorNavigationInvoicesVue,
    AppAdministratorInvoicesExcerptsRootListIndexVue,
  },
})
export default class AppAdministratorInvoicesExcerptsRootIndexVue extends Vue {}
