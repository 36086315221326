
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppExcerptCreateIndex} from "@/models/app/excerpt/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
} from "element-plus";
import {
  Plus,
  Upload,
} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    Plus,
    Upload,
  },
})
export default class AppAdministratorInvoicesExcerptsListFormCreateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  events = ["dragenter", "dragover", "dragleave", "drop"];

  formData: AppExcerptCreateIndex = new AppExcerptCreateIndex();
  formDataRules = {
    file: [
      {
        required: true,
        trigger: "blur",
      },
    ],
  };

  get translation(): any {
    return getTranslation([
      "add",
      "choose",
      "dragAndDropXMLFile",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
    formFileInput: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;

    const formData = new FormData();

    formData.append("organization_id", this.formData.organization_id.toString());
    formData.append("file", this.formData.file);

    await postRequest({
      uri: "/common/excerpt/create",
      formData: formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$router.push({
          name: "AppAdministratorInvoicesExcerptsDetailsIndexVue",
          params: {
            id: r.data.id
          }
        });
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AppExcerptCreateIndex();
    this.$refs.formFileInput.value = null;
  }

  onClickChoose(): void {
    this.$refs.formFileInput.click();
  }

  onDrop(e: any): void {
    if (e.dataTransfer.files.length > 0) {
      this.formData.file = e.dataTransfer.files[0];
      this.validateForm();
    }
  }

  onChoose(): void {
    this.formData.file = this.$refs.formFileInput.files[0];
    this.validateForm();
  }

  preventDefaults(e: any): void {
    e.preventDefault();
  }

  mounted(): void {
    this.events.forEach((eventName: string) => {
      document.body.addEventListener(eventName, this.preventDefaults);
    });
  }
}
